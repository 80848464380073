import Link from 'next/link';
import { Box, Text, Link as ChakraLink } from '@chakra-ui/react';
import VendorLayout from 'components/VendorLayout';
import { brandColours } from 'theme/aa/aa-theme';

export default function Custom404() {
  return (
    <VendorLayout hasMenu={false} pageTitle="We couldn’t find that page">
      <Box as="h1" textStyle="h1">
        We couldn’t find that page
      </Box>
      <Text mb="24px">
        Check the link is spelled correctly or go back to the{' '}
        <Link href="/" passHref>
          <ChakraLink color={brandColours.blue}>home page</ChakraLink>
        </Link>
        .
      </Text>
      <Text mb="12px" color="#717171">
        Error code: 404 Not Found
      </Text>
    </VendorLayout>
  );
}
